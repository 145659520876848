import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fade-transition"},[_c(VRow,{staticClass:"statistic-cards mt-n4"},[_c(VCol,{staticClass:"pb-2",attrs:{"lg":"3","cols":"sm"}},[_c(VHover,{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'statistic-cards-hover': hover },staticStyle:{"border-left":"var(--v-primary-base) solid"},attrs:{"elevation":hover ? 5 : 0},on:{"click":function($event){_vm.status = 'All'}}},[_c(VRow,{staticClass:"no-gutters"},[_c('div',{staticClass:"col-9"},[_c(VRow,[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"primary fill-height"},[_vm._v(" ")])]),_c('div',{staticClass:"col pa-3 py-4 primary--text"},[_c('h5',{staticClass:"text-truncate text-uppercase"},[_vm._v("ALL")]),_c('h1',[_vm._v(_vm._s(_vm.total))])])])],1),_c('div',{staticClass:"col-3"},[_c(VCardActions,[_c(VAvatar,{attrs:{"color":"primary lighten-4","size":"50"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-cash")])],1)],1)],1)])],1)]}}])})],1),_c(VCol,{staticClass:"pb-2",attrs:{"lg":"3","cols":"sm"}},[_c(VHover,{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'statistic-cards-hover': hover },staticStyle:{"border-left":"var(--v-primary-base) solid"},attrs:{"elevation":hover ? 5 : 0},on:{"click":function($event){(_vm.status = 'Open'), (_vm.imprestStatus = 'Pending')}}},[_c(VRow,{staticClass:"no-gutters"},[_c('div',{staticClass:"col-9"},[_c(VRow,[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"primary fill-height"},[_vm._v(" ")])]),_c('div',{staticClass:"col pa-3 py-4 primary--text"},[_c('h5',{staticClass:"text-truncate text-uppercase"},[_vm._v("PENDING")]),_c('h1',[_vm._v(_vm._s(_vm.countPendingRequests))])])])],1),_c('div',{staticClass:"col-3"},[_c(VCardActions,[_c(VAvatar,{attrs:{"color":"primary lighten-4","size":"50"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-timelapse")])],1)],1)],1)])],1)]}}])})],1),_c(VCol,{staticClass:"pb-2",attrs:{"lg":"3","cols":"sm"}},[_c(VHover,{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'statistic-cards-hover': hover },staticStyle:{"border-left":"var(--v-primary-base) solid"},attrs:{"elevation":hover ? 5 : 0},on:{"click":function($event){(_vm.status = 'Open'), (_vm.imprestStatus = 'Approved')}}},[_c(VRow,{staticClass:"no-gutters"},[_c('div',{staticClass:"col-9"},[_c(VRow,[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"primary fill-height"},[_vm._v(" ")])]),_c('div',{staticClass:"col pa-3 py-4 primary--text"},[_c('h5',{staticClass:"text-truncate text-uppercase"},[_vm._v("UNDER REVIEW")]),_c('h1',[_vm._v(_vm._s(_vm.countUnderReviewRequests))])])])],1),_c('div',{staticClass:"col-3"},[_c(VCardActions,[_c(VAvatar,{attrs:{"color":"primary lighten-4","size":"50"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-star-circle")])],1)],1)],1)])],1)]}}])})],1),_c(VCol,{staticClass:"pb-2",attrs:{"lg":"3","cols":"sm"}},[_c(VHover,{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'statistic-cards-hover': hover },staticStyle:{"border-left":"var(--v-primary-base) solid","cursor":"pointer"},attrs:{"elevation":hover ? 5 : 0},on:{"click":function($event){(_vm.status = 'Open'), (_vm.imprestStatus = 'Issued')}}},[_c(VRow,{staticClass:"no-gutters"},[_c('div',{staticClass:"col-9"},[_c(VRow,[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"primary fill-height"},[_vm._v(" ")])]),_c('div',{staticClass:"col pa-3 py-4 primary--text"},[_c('h5',{staticClass:"text-truncate text-uppercase"},[_vm._v("ISSUED")]),_c('h1',[_vm._v(_vm._s(_vm.countIssuedRequest))])])])],1),_c('div',{staticClass:"col-3"},[_c(VCardActions,[_c(VAvatar,{attrs:{"color":"primary lighten-4","size":"50"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked-circle")])],1)],1)],1)])],1)]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mt-3",attrs:{"tile":"","elevation":"0"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"top-bar mb-2"},[_c('h3',{staticClass:"primary--text text-lg-h5 mr-2"},[_vm._v("Requests")]),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"mx-2",attrs:{"prepend-inner-icon":"filter_alt","color":"primary","dense":"","single-line":"","hide-details":"","label":"Filter by date"},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.filterDatePicker),callback:function ($$v) {_vm.filterDatePicker=$$v},expression:"filterDatePicker"}},[_c(VDatePicker,{attrs:{"range":""},on:{"input":function($event){(_vm.filterDatePicker = false),
                    (_vm.startDate = _vm.filterDate[0]),
                    (_vm.endDate = _vm.filterDate[1])},"click":function($event){(_vm.status = 'Open'), (_vm.imprestStatus = 'Issued')}},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1),(_vm.donors.length > 0)?_c(VAutocomplete,{attrs:{"items":_vm.donors,"item-text":function (item) { return item.code + ' - ' + item.name; },"item-value":function (item) { return item.code; },"dense":"","single-line":"","hide-details":"","prepend-inner-icon":"filter_alt","label":"Filter By Donor"},model:{value:(_vm.donorFilter),callback:function ($$v) {_vm.donorFilter=$$v},expression:"donorFilter"}}):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"to":{ name: 'ImprestCard' },"color":"primary"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Request ")],1)],1),_c(VCardText,[_c(VDataTable,{staticClass:"table-font table-striped",attrs:{"headers":_vm.headers,"items":_vm.donorFilter ? _vm.donorImprests : _vm.imprests,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":5,"fixed-header":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.postingDate",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(_vm._f("formatDate")(item.postingDate)))])]}},{key:"item.description",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(_vm.readMore(item.description, 15)))])]}},{key:"item.amountToPayHeader",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(item.currencyCode.length > 0 ? ("(" + (item.currencyCode) + ")") : "(KES)")+_vm._s(_vm._f("currencyFormat")(item.amountToPayHeader)))])]}},{key:"item.imprestStatus",fn:function(ref){
                    var item = ref.item;
return [_c(VIcon,{attrs:{"small":"","left":"","color":item.imprestStatus === 'Approved' &&
                    item.status === 'Open'
                      ? _vm.getColor('Review').color
                      : _vm.getColor(item.imprestStatus).color}},[_vm._v("mdi-radiobox-marked")]),_c('span',{attrs:{"small":"","lowercase":"","text-color":"white"}},[(
                      item.imprestStatus === 'Approved' &&
                      item.status === 'Open'
                    )?_c('span',{staticClass:"text-capitalize"},[_vm._v("under-review")]):(
                      item.imprestStatus === 'Approved' &&
                      item.status === 'Released'
                    )?_c('span',{staticClass:"text-lowercase"},[_vm._v("approved")]):_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(item.imprestStatus)))])])]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1 text--white",attrs:{"color":"success","label":"","small":"","to":{
                          name: 'ImprestCard',
                          params: { code: item.no },
                        }}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"small":"","left":"","color":"white"}},[_vm._v("mdi-eye")]),_vm._v(" view ")],1)]}}],null,true)},[_c('span',[_vm._v("view Imprest Details")])]),_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(item.imprestStatus === 'Issued'),expression:"item.imprestStatus === 'Issued'"}],staticClass:"mx-1 text--white",attrs:{"color":"primary","label":"","small":"","to":{
                            name: 'ImprestAccounting',
                            params: { code: item.no },
                          }}},[_c(VIcon,{attrs:{"small":"","left":"","color":"white"}},[_vm._v("mdi-cash")]),_vm._v(" account ")],1)]}}],null,true)},[_c('span',[_vm._v("Account for imprest")])])],1),_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1 text--white",attrs:{"color":"secondary darken-1","label":"","small":"","to":{
                            name: 'ImprestDocument',
                            params: { docNo: item.no },
                          }}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"small":"","left":"","color":"white"}},[_vm._v("mdi-file")]),_c('span',{staticStyle:{"color":"#ffffff"}},[_vm._v("Imprest Report")])],1)]}}],null,true)},[_c('span',[_vm._v("view Imprest Document report")])])],1),_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1 text--white",attrs:{"color":"orange darken-1","label":"","small":""},on:{"click":function($event){return _vm.viewComments(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"small":"","left":"","color":"white"}},[_vm._v("mdi-history ")]),_c('span',{staticStyle:{"color":"#ffffff"}},[_vm._v("View Comment")])],1)]}}],null,true)},[_c('span',[_vm._v("View Impress Cash Comment History")])]),(item.status == 'Rejected')?_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"orange darken-1","label":"","small":"","dark":""},on:{"click":function($event){return _vm.reopendoc(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(" mdi-pen ")]),_c('span',[_vm._v("reopen")])],1)]}}],null,true)},[_c('span',[_vm._v("Re-open document ")])])],1):_vm._e()],1)],1)]}}])})],1)],1)],1)],1)],1),_c('ImprestCashComment',{attrs:{"cashComments":_vm.selectedComments ? _vm.selectedComments : [],"dialog":_vm.viewComment},on:{"setCommentDialog":_vm.setCommentDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }